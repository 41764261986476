@import "styles/variables";

.root {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;
  font-family: $font-family-sans-serif;
  &__modifierFixed {
    table-layout: fixed;
  }

  &__tr {
    background-color: $white;
    &_modifierOdd {
      background-color: $gray-100;
    }
  }

  &__th {
    font-family: $font-family-sans-serif-featured;
    text-transform: uppercase;
    border-bottom: 2px solid $black;
    padding: 0.5rem;
    &_modifierTextAlignLeft {
      text-align: left;
    }
    &_modifierBorderLight {
      border-bottom: 2px solid $gray-300;
    }
  }

  &__th,
  &__td {
    text-align: center;
    &_modifierTextAlignLeft {
      text-align: left;
    }
  }

  &__td {
    padding: 0.8rem 0.5rem;
  }
}
