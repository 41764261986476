@import "styles/variables";

.root {
  z-index: 1010;
  position: fixed;
  left: 24px;
  bottom: 24px;
  border-radius: $border-radius-lg;
  width: 230px;
  height: 30px;
  background-color: $info;
  color: $white;
  padding: 16px;
  display: flex;
  align-items: center;

  &_modifierBig {
    width: 350px;
    height: 70px;
  }

  &_modifierDanger {
    background-color: $danger;
  }

  &_modifierWarning {
    background-color: $warning;
  }

  &_modifierSuccess {
    background-color: $success;
  }

  &__icon {
    flex: none;
    display: flex;
    align-items: center;
    width: 60px;
  }
  &__aside {
    flex: none;
  }
  &__body {
    flex: 1 1 auto;
  }
  &__message {
    font-family: $font-family-sans-serif-bold;
    font-size: 0.8rem;
    margin: 0;
  }
  &__dismiss {
    background-color: transparent;
    border: none;
    color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 1rem;
  }
}
