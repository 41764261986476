@import "styles/variables";
@import "styles/elevation";
@import "styles/buttonReset";

$header-height: $bar-height * 2;
$header-navigation-margin: 0.5rem;
$header-button-margin: 0.75rem;
$header-selected-link-width: 3px;
$header-font-size: 0.9rem;

.root {
  background-color: $white;
  z-index: 2;
  width: 100%;
  @include elevation(4);
  position: fixed;
  top: 0;
  left: 0;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    margin: 0.6rem 1rem;
    color: $accent;
    font-style: italic;
    letter-spacing: -3px;
    font-family: $font-family-sans-serif-bold;
  }

  &__navigation {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    margin: 0 $header-navigation-margin;
  }
  &__user {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    font-family: $font-family-sans-serif-featured;
    margin-right: $header-button-margin;
    font-size: $header-font-size * 1.1;
    align-items: center;
  }
  &__role {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    margin-left: $header-button-margin;
    font-size: $header-font-size * 0.8;
    font-family: $font-family-sans-serif;
    color: $gray-800;
    text-transform: uppercase;
  }
  &__link {
    font-size: $header-font-size;
    cursor: pointer;
    padding: 0 $header-button-margin;
    font-family: $font-family-sans-serif-bold;
    text-decoration: none;
    line-height: calc(#{$header-height} - #{$header-selected-link-width});
    &_modifierSelected {
      color: $accent;
      border-bottom: $header-selected-link-width solid $accent;
    }
    &:hover {
      color: $accent;
    }
  }
  @media print {
    display: none;
  }
}

.button {
  @include button-reset;
  font-family: $font-family-sans-serif-featured;
  color: $black;
  font-size: $header-font-size;
  cursor: pointer;
  padding: 0 $header-button-margin 0 0;
  &:hover {
    color: $accent;
  }
}

.pageView {
  z-index: 1;
  position: absolute;
  top: $header-height;
  left: 0;
  right: 0;
  height: calc(100% - #{$header-height});
  overflow: auto;
  &__content {
    padding: 1.6rem 1rem;
  }
  @media print {
    top: 0;
    height: auto;
    position: static;
  }
}
