@import "styles/variables";

.root {
  font-family: $font-family-sans-serif-bold;
  color: $danger;
  display: none;
  font-size: 0.75rem;
  &_modifierLate {
    display: block;
  }
  @media print {
    font-size: 0.55rem;
  }
}
