@import "styles/variables";

.root {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 0.3rem 0;
  padding: 0.4rem;
  border: 1px solid $gray-300;
  border-radius: $border-radius-lg;
  &__main {
    padding: 0.2rem 0.3rem;
    text-align: left;
    font-size: 0.8rem;
    flex: none;
    width: 100%;
    @media print {
      font-size: 0.5rem;
      padding: 0rem;
    }
  }
  &__dates {
    padding: 0.2rem;
    display: block;
    @media print {
      padding: 0 0.2rem;
    }
  }
  &__meta {
    padding: 0.2rem;
    display: block;
    @media print {
      padding: 0;
    }
  }
}
