:root {
  --amplify-font-family: #{$font-family-sans-serif};
  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.81rem;
  --amplify-text-sm: 0.875rem;
  --amplify-text-md: 1rem;
  --amplify-text-md-sub: 1.15rem;
  --amplify-text-lg: 1.5rem;
  --amplify-text-xl: 2rem;
  --amplify-text-xxl: 2.5rem;
  --amplify-primary-color: #{$primary};
  --amplify-primary-contrast: var(--amplify-white);
  --amplify-primary-tint: #{$primary};
  --amplify-primary-shade: #{$primary};
  --amplify-secondary-color: #{$primary};
  --amplify-secondary-contrast: var(--amplify-white);
  --amplify-secondary-tint: #{$primary};
  --amplify-secondary-shade: #{$primary};
  --amplify-tertiary-color: #{$secondary};
  --amplify-tertiary-contrast: var(--amplify-white);
  --amplify-tertiary-tint: #{$secondary};
  --amplify-tertiary-shade: #{$secondary};
  --amplify-background-color: var(--amplify-white);
  --amplify-grey: #{$gray-800};
  --amplify-light-grey: #{$gray-400};
  --amplify-white: #{$white};
  --amplify-smoke-white: #{$gray-100};
  --amplify-red: #{$danger};
  --amplify-blue: #{$info};
}
