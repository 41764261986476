@import "styles/variables";
@import "styles/elevation";

.root {
  position: relative;
  &__menu {
    right: 0;
    top: 42px;
    bottom: auto;
    position: absolute;
    z-index: 2;
    @include elevation(4);
    border-radius: $border-radius-sm;
    padding: 0.8rem;
    min-width: 20ch;
    display: none;
    background-color: $white;
    &_modifierOpen {
      display: block;
    }
    &_modifierDisplayedTop {
      bottom: 42px;
      top: auto;
    }
  }
  &__icon {
    margin-right: 0.3rem;
    font-size: 22px !important;
  }
  &__action {
    border: none;
    background-color: transparent;
    font-family: $font-family-sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
    color: $black;
    border-bottom: 1px solid $gray-200;
    padding: 0.25rem 0.55rem;
    min-height: 40px;
    line-height: 1.1;
    font-size: 0.75rem;
    text-transform: capitalize;
    width: 100%;
    &:last-of-type {
      border-bottom: none;
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
    }
    &:disabled {
      color: $gray-400;
    }
    &:hover {
      background-color: $gray-100;
    }
  }
  &__backdrop {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    &_modifierOpen {
      display: block;
    }
  }
}
