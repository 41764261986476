@import "styles/variables";
@import "styles/elevation";

$width: 200px;
$height: 40px;
$top: $bar-height;

.root {
  border-radius: $border-radius-md;
  z-index: 1020;
  position: fixed;
  top: $top;
  margin-left: -$width/2;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid $black;
  left: 50%;
  width: $width;
  height: $height;
  background: $white;
  @include elevation(2);
}
