@import "styles/variables";

.selectionContainer {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  &__item {
    justify-content: space-between;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex: 25%;
    padding: 0.8rem 1rem;
    margin: 0.2rem;
    background-color: rgba($info, 0.1);
    border-radius: $border-radius-md;
    &_modifierFirst {
      background-color: rgba($warning, 0.1);
    }
    &_modifierCanceled {
      background-color: rgba($danger, 0.1);
    }
    &_modifierLast {
      background-color: rgba($success, 0.1);
    }
  }
}
