@import "styles/variables";

$tabs-border-color: $gray-200;
$tabs-border-active-color: $accent;
$tabs-color: $black;

.root {
  border-bottom: 1px solid $tabs-border-color;
  &__item {
    margin-bottom: -1px;
    text-transform: capitalize;
    background-color: transparent;
    font-family: $font-family-sans-serif-featured;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    background-color: $white;
    color: $tabs-color;
    border: none;
    border-bottom: 1px solid $tabs-border-color;
    padding: 0.25rem 0.55rem;
    min-width: 10ch;
    min-height: 40px;
    text-align: center;
    line-height: 1.1;
    font-size: 0.8rem;
    &:hover,
    &:active,
    &_modifierActive {
      border-bottom: 1px solid $tabs-border-active-color;
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      background-color: scale-color($white, $lightness: -10%);
    }
  }
}
