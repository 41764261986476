html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  @media print {
    font-size: 12px;
  }
}

body {
  font-family: $font-family-base;
  font-size: 0.8rem;
}

.text-featured {
  font-family: $font-family-sans-serif-featured;
  font-size: 1rem;
}

strong,
.text-bold {
  font-family: $font-family-sans-serif-bold;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family-sans-serif-bold;
  letter-spacing: -1px;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
  text-transform: uppercase;
}

@include for-tablet-only {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }
}

p {
  font-size: 0.75rem;
}

label {
  font-size: 0.675rem;
}

dt,
label {
  font-family: $font-family-sans-serif-featured;
  font-size: 0.675rem;
  display: block;
  color: $gray-800;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.175rem;
}

dd {
  font-size: 1rem;
  margin-inline-start: 0px;
}

a {
  color: $primary;
}

select,
input[type="text"] {
  padding: 0.3rem 0.6rem;
  border-radius: $border-radius-sm;
  border: 1px solid $gray-400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus,
  &:active {
    border: 1px solid $accent;
  }
  &:focus {
    outline: none;
  }
}

select {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -5px;
  padding-right: 2rem;
}

// fix material icons
.vertical-align-middle {
  vertical-align: middle !important;
}
