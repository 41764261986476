@import "styles/variables";

.root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__label {
    width: 100%;
    flex: none;
  }
  &__input {
    flex: 1;
  }
  &__info {
    flex: 1;
  }
  &__actionWrapper {
    flex: none;
    margin: 0 0 0 0.5rem;
  }
}
