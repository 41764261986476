@import "styles/variables";
@import "styles/elevation";

.root {
  text-transform: uppercase;
  background-color: $info;
  color: $white;
  font-size: 0.6rem;
  display: inline-flex;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  border-radius: $border-radius-md;
  padding: 0.05rem 0.65rem;
  min-width: 5ch;
  min-height: 20px;
  font-family: $font-family-sans-serif-featured;
  &_modifierDanger {
    background-color: $danger;
  }
  &_modifierWarning {
    background-color: $warning;
  }
  &_modifierSuccess {
    background-color: $success;
  }
  &_modifierClickable {
    cursor: pointer;
    &:hover {
      @include elevation(2);
    }
  }
  @media print {
    min-height: 10px;
    padding: 0;
    background-color: none;
  }
}
