@import "styles/variables";
@import "styles/elevation";

.root {
  display: inline-flex;

  &__list {
    margin: 0 0 0 1.5rem;
    padding: 0;
    list-style: none;
    display: inline-flex;
  }

  &__option {
    font-family: $font-family-sans-serif-bold;
    color: $black;
    font-size: 1rem;
    cursor: pointer;
    padding: 0 1rem 0 0;
    &:hover {
      color: $accent;
    }
    &_modifierSelected {
      color: $accent;
    }
  }
}
