/*
*
* Logica Fonts Set
*
*/

/* Open Sans - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/OpenSans-Regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"),
    url("./fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/OpenSans-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/OpenSans-Regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/OpenSans-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/OpenSans-Regular.svg") format("svg"); /* Legacy iOS */
}

/* Open Sans Semibold - latin */
@font-face {
  font-family: "Open Sans Semibold";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/OpenSans-SemiBold.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Semibold"),
    url("./fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/OpenSans-SemiBold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/OpenSans-SemiBold.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/OpenSans-SemiBold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/OpenSans-SemiBold.svg")
      format("svg"); /* Legacy iOS */
}

/* Open Sans Bold - latin */
@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/OpenSans-Bold.eot"); /* IE9 Compat Modes */
  src: local("Open Sans bold"),
    url("./fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/OpenSans-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/OpenSans-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/OpenSans-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/OpenSans-Bold.svg") format("svg"); /* Legacy iOS */
}
