@import "styles/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.5rem 0;
  &__fieldset {
    flex: 1 1 auto;
    border: 1px solid $gray-200;
    border-radius: $border-radius-sm;
    padding: 0.3rem 0.5rem;
  }
  &__fieldsetLabel {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-family: $font-family-sans-serif-featured;
  }
  &__input {
    margin: 0.1rem 0.5rem 0.1rem 0;
  }
  &__btnContainer {
    flex: none;
    margin: 0.5rem;
  }
}
