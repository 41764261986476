@import "styles/variables";

.missingDays {
  font-family: $font-family-sans-serif-featured;
  &__counter {
    font-family: $font-family-sans-serif-bold;
    &_modifierLate {
      color: $danger;
    }
  }
}
