@import "styles/variables";

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  &__message {
    text-align: center;
  }
}
