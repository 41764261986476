@import "styles/variables";

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 997;
  outline: 0;
  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &__dialog {
    z-index: 999;
    width: 930px;
    position: relative;
    margin: 1.75rem auto;
    height: calc(100% - 3.5rem);
    @include for-tablet-only {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}

.layout {
  border-radius: $border-radius-md;
  @include for-tablet-only {
    border-radius: 0;
  }
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 100%;
  &__header {
    flex: none;
    height: 3rem;
    font-size: 1.2rem;
    font-family: $font-family-sans-serif-bold;
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    width: calc(100% - 3rem);
    overflow: auto;
    flex: 1 1 auto;
    max-height: calc(100vh - 3rem);
    padding: 0 1.5rem 1.3rem 1.5rem;
  }
}
